$(function () {
    const inputs = $('form input[required]');
    const selects = $('form select');

    function enableButton() {
        setTimeout(() => {
            let empty = false;
            const form = $(this).parents('form');

            form.find('select.md-form').each(function () {
                empty = $(this).val() === null;

                if (empty === true) {
                    return false;
                }
            });

            if (empty === false) {
                form.find('input.form-control[required]').each(function () {
                    empty = $(this).val().length === 0;

                    if (empty === true) {
                        return false;
                    }
                });
            }

            if (empty === true) {
                form.find('button[type=submit].form-filled')
                    .attr('disabled', 'disabled')
                    .addClass('btn-outline-primary')
                    .removeClass('btn-primary');
            } else {
                form.find('button[type=submit].form-filled')
                    .attr('disabled', false)
                    .addClass('btn-primary')
                    .removeClass('btn-outline-primary');
            }
        }, 0);
    }

    inputs.on('keyup', enableButton);
    inputs.on('paste', enableButton);
    selects.on('change', enableButton);
});
