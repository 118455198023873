import '../functions/translate';

$(function () {
    window.handleErrors = function (response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    };

    window.simpleAjaxRequest = function (url, element) {
        fetch(url, { mode: 'cors' })
            .then(handleErrors)
            .then((res) => res.text())
            .then((response) => {
                element.html(response);
            })
            .catch((error) => {
                element.html(
                    `<div class="alert alert-warning" role="alert">
                            <span>
                            <strong>` +
                        translate('app.error', {}, 'flashes') +
                        `:</strong>
                            &nbsp;` +
                        translate('app.api.error.general', {}, 'flashes') +
                        `<br>
                            </span>
                            </div>`
                );
            });
    };
});
