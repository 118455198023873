$(function () {
    window.translate = function (transKey, params = [], context = 'messages') {
        if (
            'undefined' === typeof translations ||
            'undefined' === typeof translations[context][transKey]
        ) {
            return transKey;
        }

        let trans = translations[context][transKey];

        for (const [key, value] of Object.entries(params)) {
            trans = trans.replace(key, value);
        }

        return trans;
    };
});
