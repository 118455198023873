import Cookies from 'js-cookie';

document.querySelector('#consentAll').addEventListener('click', () => {
    const consentList = [];
    const consentInputs = document.querySelectorAll('.consentInput');

    for (const input of consentInputs) {
        consentList.push(input.name);
    }

    Cookies.set('consent', consentList.join(','));

    window.location.reload();
});

document.querySelector('#consentSave').addEventListener('click', () => {
    const consentList = [];
    const consentInputs = document.querySelectorAll('.consentInput');

    for (const input of consentInputs) {
        if (input.checked) {
            consentList.push(input.name);
        }
    }

    Cookies.set('consent', consentList.join(','));

    window.location.reload();
});

const consentRevoke = document.querySelector('#consentRevoke');

if (consentRevoke) {
    consentRevoke.addEventListener('click', (event) => {
        Cookies.remove('consent');

        alert(event.target.getAttribute('data-notice'));

        window.location.reload();
    });
}

window.addEventListener('DOMContentLoaded', () => {
    const consent = Cookies.get('consent');

    if ('undefined' === typeof consent) {
        $('#consentModal').modal('show');
        return;
    }

    for (const consentType of consent.split(',')) {
        document.body.classList.add(`consent-${consentType}`);
    }
});
