import '../functions/translate';
import * as Sentry from '@sentry/browser';

$(function () {
    window.openModal = function (modalElement, url) {
        fetch(url, { mode: 'cors' })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }

                return response;
            })
            .then((res) => res.text())
            .then((response) => {
                modalElement.html(response);
                modalElement.modal('show');
            })
            .catch((error) => {
                element.html(
                    `<div class="alert alert-warning" role="alert">
                        <span>
                        <strong>` +
                        translate('app.error', {}, 'flashes') +
                        `:</strong>
                        &nbsp;` +
                        translate('app.api.error.general', {}, 'flashes') +
                        `<br>
                        </span>
                        </div>`
                );

                Sentry.captureException(error);
            });
    };
});
