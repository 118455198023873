$(function () {
    $('.navbar-toggler').on('click', function () {
        $('.animated-icon').toggleClass('open');
    });

    $(document).on('click', function (event) {
        let clickedElement = $(event.target),
            opened = $('.navbar-collapse').hasClass('show');

        if (opened === true && !clickedElement.hasClass('navbar-toggler')) {
            $('button.navbar-toggler').trigger('click');
        }
    });
});
