$(function () {
    const textarea = $('textarea.md-textarea'),
        length = textarea.val() === undefined ? 0 : textarea.val().length,
        maxLength = textarea.attr('length'),
        characterCounter = textarea.parent().find('span.character-counter');

    characterCounter.html(
        maxLength - length + ' von ' + maxLength + ' Zeichen'
    );

    if (!textarea.val()) {
        textarea.css('border', '1px solid #FFFFFF');
        textarea.css('border-bottom', '1px solid #999999');
    } else {
        textarea.css('border', '1px solid #999999');
        textarea.css('border-bottom', '1px solid #999999');
    }

    textarea.on('change', function () {
        changeElement($(this));
    });

    textarea.on('keyup', function () {
        changeElement($(this));
        updateCharacterCounter($(this));
    });

    function changeElement(element) {
        let textareaElement = element[0];

        if (!element.val()) {
            element.css('border', '1px solid #FFFFFF');
            element.css('border-bottom', '1px solid #999999');
        } else {
            element.css('border', '1px solid #999999');
            element.css('border-bottom', '1px solid #999999');
        }

        textareaElement.style.height = '1px';
        textareaElement.style.height = textareaElement.scrollHeight + 'px';
    }

    function updateCharacterCounter(element) {
        let length = element.val().length,
            maxLength = element.attr('length'),
            characterCounterElement = element
                .parent()
                .find('span.character-counter');

        let remaining = maxLength - length;

        if (length > maxLength) {
            element.val(element.val().substring(0, maxLength));
            remaining = 0;
        }

        characterCounterElement.html(
            remaining + ' von ' + maxLength + ' Zeichen'
        );
    }
});
