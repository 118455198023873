$(function () {
    $('.password-icon').on('click', function () {
        let passwordField = $(this)[0].previousElementSibling;

        if (passwordField.type === 'password') {
            passwordField.type = 'text';
        } else {
            passwordField.type = 'password';
        }
    });
});
