$(function () {
    const body = $('body');
    const content = $('#app main');
    let loader = undefined;

    window.initLoader = function () {
        // remove possible previous loader
        deinitLoader();

        body.prepend(
            '<div class="loader d-none">' +
                '<div class="circle"></div>' +
                '</div>'
        );

        loader = body.children('.loader');
    };

    window.deinitLoader = function () {
        body.remove('.loader');
    };

    window.startLoader = function () {
        if (loader === undefined) {
            initLoader();
        }

        loader.removeClass('d-none');
        content.addClass('loader-blur');
    };

    window.stopLoader = function () {
        loader.addClass('d-none');
        content.removeClass('loader-blur');
    };
});
